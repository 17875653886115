/* Dashboard Header Container */
.dashboadr-header_container {
    display: flex;
    justify-content: space-between;
}

/* Header Section */
.div-dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 32px;
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
    /* left: 300px; */
}

/* Link Styling */
.link {
    width: auto;
    padding: 0px 20px;
    color: black;
    font-size: 1.25rem; /* Default font size */
    font-weight: 500;
    text-decoration: none;
}

.link.active {
    width: auto;
    height: 42px;
    display: flex;
    font-weight: 600;
    align-items: center;
    padding: 0px 20px;
    border-radius: 6px;
    justify-content: center;
    color: #fff !important;
    background-color: #3CB9D4;
}

/* Button Container */
.button {
    display: flex;
    gap: 15px;
    width: auto;
    position: relative;
    flex-direction: row-reverse;
}

.button .custom-button {
    font-size: 1.2rem;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; 
  }

/* Responsive Adjustments */
@media only screen and (max-width: 1024px) {
    .button .custom-button {
        font-size: 1rem; 
        padding: 6px 12px; 
      }

    .link {
        font-size: 1rem;
        padding: 0px 15px;
    }

    .link.active {
        font-size: 1rem;
        padding: 0px 15px;
    }
}

@media only screen and (max-width: 768px) {
    .div-dashboard-header {
        padding: 0px 16px;
        left: 200px;
        height: 70px;
    }

    .link,
    .link.active {
        font-size: 0.9rem; 
        padding: 0px 12px;
    }

    .button {
        gap: 10px;
        width: 100%;
        justify-content: space-between;
      }
    
      .button .custom-button {
        font-size: 0.9rem; 
        padding: 5px 10px;
      }
}

@media only screen and (max-width: 480px) {
    .div-dashboard-header {
        padding: 0px 10px;
        left: 0; 
    }

    .link,
    .link.active {
        font-size: 0.8rem; 
        padding: 0px 10px;
    }

    .button {
        gap: 5px;
        width: 100%;
        flex-direction: column; 
        align-items: flex-start;
    }
}
