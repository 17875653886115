.data_vazualization-main_container::-webkit-scrollbar{
  display: none;
}
.data_vazualization-main_container {
  overflow-y: auto;
}

.main-visualization {
  margin: 20px;
  display: flex;
  justify-content: space-around;
}

.main-visualization .card-visual-clr1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 220px;
  padding: 15px;
  background-color: #f2e3ff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 220px;
  padding: 15px;
  background-color: #fed0e2;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 220px;
  padding: 15px;
  background-color: #d5ffce;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 220px;
  padding: 15px;
  background-color: #fff2c5;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 220px;
  padding: 15px;
  background-color: #d9eafe;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .card-visual-clr6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 220px;
  padding: 15px;
  background-color: #f1dfdf;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-visualization .img-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  /* padding: 17px 10px; */
}

.main-visualization .img-icon p,
.main-visualization p {
  font-size: 16px;
  font-weight: 500;
  color: #023047;
}

.hr {
  border-bottom: 1px solid #dcdcdc;
}

.main-schedule {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 35px;
  margin-right: 150px;
}

.chart-container {
  margin: 10px 90px;
}