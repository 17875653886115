.clinic-details-main-container::-webkit-scrollbar,
.assistant_card_container-clinic::-webkit-scrollbar {
    display: none;
}

.clinic-details-main-container {
    background-color: #fff;
    top: 88px;
    left: 112px;
    right: 20px;
    bottom: 20px;
    width: auto;
    height: auto;
    position: absolute;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 32px;
    overflow-y: auto;
    text-align: left;
}

.doctorClinic-details-card_Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -30px;
}

.doctorClinicDetails-card-BackLink {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    margin-bottom: 10px;
    height: 40px;
    background-color: #66D348;
    padding: 10px 30px;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

/* .doctorClinicDetails-logo-Header img {
    width: 150px;
    height: 100px;
} */

.doctorClinicDetails-logo-Header>h2 {
    font-family: inter;
    font-size: 28px;
    font-weight: 600;
    padding: 39px;
}

.blank-section {
    width: 10%;
    /* background-color: red; */
}

.clinic-details-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.clinic-details-left_section {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* width: 80%; */
}

.about_Heading,
.slot_Heading,
.avilable-doctor-heading {
    font-size: 25px;
    font-weight: 600;
    font-family: inter;
}

.about_Heading {
    padding-bottom: 10px;
}

.clinic-details-left_section-header-left img {
    width: 100%;
    height: 400px;
}

.clinic-details-left_section-header-right {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    gap: 20px;
}

.clinic-details_about {
    width: 50%;
}

.clinic-details_about p {
    font-size: 15px;
}

.clinic-details_location-container {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 10px 0px;
    margin-top: 20px;
}

.clinic-location-box {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #c6def9;
    padding: 10px 20px;
    border-radius: 10px;
    width: 70%;
}

.clinic_location-e {
    color: red;
}

.clinic-details_edit-on-map {
    padding: 12px 40px;
    background-color: #FFB703;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
}

.avilableDoctor_container-main {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.avilable-doctor_add-button {
    display: flex;
    align-items: center;
    padding: 8px 30px;
    background-color: #FFB703;
    border: 1px solid #FFB703;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    gap: 10px;
    cursor: pointer;
}

.clini-details-doctor_container {
    display: flex;
    flex-wrap: wrap;
    /* background-color: red; */
    margin-top: 30px;
    gap: 15px;
    padding: 0;
}

.clinic-details_doctor-box {
    display: flex;
    width: 380px;
    height: 130px;
    background-color: #f4f7fb;
    border-radius: 8px;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: relative;
    /* Added for the close icon positioning */
}

.doctor-image-container img {
    width: 140px;
    height: 130px;
    border-radius: 8px;
}

.doctor-text {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    gap: 5px;
}

.Online-Offline_selector {
    width: 150px;
    outline: none;
}

.remove-doctor-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    cursor: pointer;
    padding: 3px;
}

.clinic-details_slot-section .headingcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-bottom: 30px;
}

.headingcontainer .add-slot {
    display: flex;
    align-items: center;
    padding: 8px 30px;
    background-color: #FFB703;
    border: 1px solid #FFB703;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    gap: 10px;
    cursor: pointer;
}

.clinic-details_slot-section-container {
    display: flex;
    flex-direction: row;
}

.day-column {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    /* gap: 60px; */
}

.day-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 36px;
    text-align: center;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    background-color: #c6def9;
    margin-bottom: 30px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.morning-time-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
}

.time-box {
    width: 160px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 30px;
    padding: 5px 10px 5px 20px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    gap: 5px;
}

.available-slot-cross_icon {
    background-color: #e4e4e5;
    border-radius: 50%;
    padding: 3px;
    /* height */
}

.header {
    background-color: #fff;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.container {
    display: flex;
}

.main-image img {
    width: 950px;
    height: 420px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.side-images {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.side-images img {
    width: 250px;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.see-more-image-container {
    position: relative;
}

.see-more-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    top: 4%;
    /* left: 50%; */
    width: 80%;
    max-height: 85%;
    overflow-y: auto;
}

.modal-content::-webkit-scrollbar {
    display: none;
}

.modal-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.modal-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 35px 0 0 0;
}

.image-container {
    position: relative;
    width: 250px;
    height: 200px;
}

.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.image-container .remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    cursor: pointer;
}

.add-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f7fb;
    color: #000;
    border-radius: 10px;
    cursor: pointer;
    height: 200px;
    width: 210px;
}

.add-image-container .add-icon {
    font-size: 48px;
}

.add-image-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.location-input {
    border: none;
    background-color: #c6def9;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    width: 80%;
    outline: none;
}

.fullscreen-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
}

.fullscreen-modal-content {
    position: relative;
}

.fullscreen-modal-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.fullscreen-modal-content img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 500px;
    border-radius: 10px;
    object-fit: cover;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .main-image img {
        width: 100%;
        height: auto;
    }

    .side-images {
        flex-direction: row;
        gap: 10px;
    }

    .side-images img {
        width: 100px;
        height: 100px;
    }
}

.horizintal-line-clinic-details-card {
    width: 100%;
    margin-top: 40px;
}