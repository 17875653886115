.home_container {
  background-color: #f8fbfc;
  width: auto;
  height: auto;
  position: fixed;
  top: 88px;
  left: 112px;
  right: 20px;
  bottom: 10px;
  border-radius: 32px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow-y: hidden;
}

.feed {
  display: flex;
  flex-direction: column;
}

/* STYLES HEADER SECTION  */

.top-section {
  flex: 1;
  display: flex;
  align-items: center;
  margin: 20px 32px 20px 32px;
  justify-content: space-between;
  height: 75px;
  gap: 20px;
}

.search {
  display: flex;
  align-items: center;
  width: 500px;
  height: 40px;
  gap: 15px;
  border-radius: 6px;
  padding-left: 20px;
  border: 1px solid #989898;
}

.searchtext {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.select-date {
  width: 300px;
  height: 40px;
  border: 1px solid#989898;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.select-date label {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.select-date,
.icon,
label:hover {
  cursor: pointer;
}

.section-condition {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 300px;
  height: 40px;
  gap: 25px;
  border-radius: 6px;
  border: 1px solid #989898;
  font-size: 18px;
  font-weight: 400;
}

.add_patient-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #ffd56d;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  height: auto;
  border: none;
  white-space: nowrap;
}

.downbutton {
  width: 20px;
  height: 11px;
}

.my-dropdown-control {
  width: 300px;
  height: 40px;
  font-size: 18px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid #989898;
}

/* STYLES BOTTOM SECTION */

.bottom-section {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 32px;
}

.today_heading {
  font-size: 30px;
  font-weight: 700;
  color: #023047;
}

.card_feed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-drag {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: row;
  height: auto;
  border-radius: 16px;
}

.card-main_box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.card-main_box .card-section-1 {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 15px;
}

.card-main_box .card-section-1 img {
  max-width: 100px;
  max-height: 60px;
  border-radius: 100px;
  border: 1px solid #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.card-main_box .card-section-1 p {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.card-main_box .card-section-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}

.card-main_box .card-section-2 .button-status {
  width: 107px;
  height: 28px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}

.card-main_box .card-section-2 span {
  font-size: 16px;
  font-weight: 500;
  color: #023047;
}

.edit-icon-container {
  display: flex;
  flex-direction: row-reverse;
}

.edit-icons {
  font-size: 26px !important;
  background-color: #F2E3FF;
  border-radius: 30px;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

.container-appointment_select-slotPage {
  position: absolute;
  top: 40px;
  left: 40%;

}

.column::-webkit-scrollbar {
  display: none;
}

.column {
  background-color: #f4f5f7;
  margin: 1rem 2rem;
  margin-left: 0;
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: auto; */
  height: 66vh;
  overflow-y: auto;
}

.column-header {
  padding: 0.6rem 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-weight: bold;
  text-align: center;
}

.upcoming {
  background-color: #d9eafe;
}

.in-progress {
  background-color: #ffe8a5;
}

.for-testing {
  background-color: #dcdcdc;
}

.completed {
  background-color: #daf7d0;
}

.column-content {
  padding: 0.5rem;
}

.droppable-area {
  padding: 0.5rem;
  flex-grow: 1;
  min-height: 100px;
}

.button-addmedicine {
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #ffd56d;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  height: auto;
  border: none;
  white-space: nowrap;
}

.icon {
  width: 18px;
  height: 18px;
}

.boardDataNotAvil {
  width: 100%;
  text-align: center;
  margin-top: 15%;
  font-size: 25px;
  font-weight: 500;
  color: gray;
}

/* getEventColor change button */
.new-bg {
  background-color: #ffd56d;
}

.regular-bg {
  background-color: #4868f6;
}

.critical-bg {
  background-color: #e2515e;
}

.published-bg {
  border: 1px solid #219ebc;
  background-color: white;
}

/* getTextColor text color change */
.new {
  color: black;
}

.regular,
.critical {
  color: white;
}

.published {
  color: #219ebc;
}

/* CALENDAR STYLES HERE ==== */

.date-month-year-align {
  display: flex;
  justify-content: space-evenly;
}

.clear-date-button {
  margin: 0.5rem 0 0 0;
  border: 1px solid #989898 !important;
}

.year-dropdown,
.month-dropdown,
.clear-date-button {
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #989898;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 3px;
}

.arrow-calendar {
  padding: 3px;
  border-radius: 6px;
  border: 1px solid #989898;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}

.react-datepicker__day--highlighted {
  background-color: #4868F6;
}

.react-datepicker__day--highlighted:hover {
  background-color: #4868F6;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__month-container {
  height: 370px !important;
}

.react-datepicker__day--selected {
  background-color: #4868F6;
  outline: none;
}



/* @media only screen and (max-width: 700px) {} */

@media only screen and (max-width: 1024px) {
  .home_container {
    top: 65px;
  }

  .top-section {
    height: 65px;
  }

  .search,
  .select-date,
  .section-condition {
    width: 180px;
  }

  .searchtext {
    font-size: 13px;
  }

  .icon {
    width: 14px;
    height: 14px;
  }

  .box-text {
    font-size: 13px;
  }

  .add_patient-button {
    width: 160px;
  }

  .downbutton {
    width: 15px;
    height: 8px;
  }

  .today_heading {
    font-size: 20px;
  }
}

/* Responsive Adjustments */
@media only screen and (max-width: 1024px) {
  .button-addmedicine,
  .add_patient-button {
    font-size: 1rem; 
    padding: 6px 12px; 
  }
}

@media only screen and (max-width: 768px) {
  .button-addmedicine,
  .add_patient-button {
    font-size: 0.9rem;
    padding: 5px 10px; 
  }
}