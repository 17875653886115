.dropdown {
    position: relative;
    width: 300px;
    font-family: Arial, sans-serif;
}

.dropdown-header {
    border: 1px solid #ccc;
    padding: 4px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-arrow {
    transition: transform 0.3s ease;
    color: #6c6c6c;
}

.dropdown-arrow.open {
    transform: rotate(180deg);
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #B0E7F1;
}

.dropdown-placeholder {
    color: #aaa;
}