.main-container {
  width: 300px;
  height: 100%;
  background-color: #fcf8f8;
  overflow-x: hidden;
  border-right: 1px solid #989898;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
}

.main-container::-webkit-scrollbar {
  display: none;
}

.card-main {
  margin: 20px 0 20px 20px;
}

.h1 {
  width: 112px;
  height: 32px;
  margin-top: 18px;
  margin-left: 28px;
  color: #023047;
}

.blood .ul .red {
  color: #e2515e;
  text-align: right;
}

h1,
select {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.main-container .records-dropdown {
  margin-left: 20px;
  margin-top: 8px;
  position: relative;
  z-index: 10;
  width: 262px;
}

.records-dropdown--is-active {
  margin-left: 20px;
  margin-top: 8px;
  position: relative;
  z-index: 10;
  width: 262px;
}

.card-main {
  height: auto;
  gap: 24px;
}

.card-one {
  width: 262px;
  height: 195px;
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: #ffffff;
  border-style: none;
  margin-top: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.blood {
  margin-top: 12px;
}

.icon-1 {
  margin-left: 5px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
}

.may {
  /* width: 75px; */
  /* height: 28px; */
  /* margin-left: 120px; */
  border-radius: 16px;
  background: #d5f3f8;
  padding: 4px 10px 5px 10px;
}

.jun {
  background-color: #ffb703;
}

.date-text {
  color: #000;
}

li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  list-style: none;
}

.blood .ul {
  height: 72px;
  width: 100%;
  color: black;
  text-align: right;
}

.blood .ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-2 {
  width: 262px;
  height: 195px;
  margin-top: 8px;
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: #ffffff;
  margin-top: 16px;
}

.li-card-2 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.linkRec.records,
.linkRec.records * {
  text-decoration: none;
}

.card-3 {
  width: 262px;
  height: 195px;
  margin-top: 8px;
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: #ffffff;
  margin-top: 16px;
  margin-bottom: 12px;
}

.main-heading {
  font-size: 22px;
  font-weight: 700;
  color: #023047 !important;
  text-decoration: none;
}

.linkRec.active {
  width: 262px;
  height: 195px;
  border-radius: 16px;
  background-color: #fff5d3;
  border-style: none;
  text-decoration: none;
  color: #023047;
}

.linkRec.active.june {
  color: #023047;
  background-color: #ffb703;
}

.active-card {
  background-color: #fff5d3;
  text-decoration: none;
}