/* Overlay styling */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Container for the form */
.contact-verification-popup-container {
    background: white;
    padding: 40px;
    border-radius: 16px;
    width: 500px;
    text-align: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.contact-verification-close-icon-container {
    display: flex;
    justify-content: end;
}

.contact-verification-close-icon {
    margin: -20px -20px 0 0;
    cursor: pointer;
    font-size: 24px;
    color: #000;
}

/* Styling for headings */
.popup-container-main-heading {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin-bottom: 20px;
}

/* Input group styling */
.input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.country-code-dropdown {
    width: auto;
    font-size: 15px;
    font-weight: 400;
    padding: 12px 0;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.contact-mobile-input,
.otp-box {
    font-size: 16px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.country-code-dropdown {
    width: 100px;
    margin-right: 10px;
}

.contact-mobile-input {
    width: 250px;
}

/* OTP Group */
.otp-group {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.otp-box {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #ccc;
}

/* Submit button */
.btn-container-otp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.submit-btn {
    padding: 12px 30px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.submit-btn:hover {
    background-color: #0056b3;
}


/* Loader.css */
.contact-verification-loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.contact-verification-loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #007bff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}