.custom-div::-webkit-scrollbar,
.react-calendar::-webkit-scrollbar,
.main-calendar::-webkit-scrollbar {
  display: none;
}

/* customCalendar.css */
.custom-div {
  width: 100%;
  max-width: auto;
  height: 100%;
  overflow: scroll;
  position: relative;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  /* background-color: white !important; */
}

.react-calendar {
  width: 100%;
  max-width: auto;
  /* height: fit-content; */
  /* position: relative; */
  background-color: #ffffff;
  border: none;
  /* margin: auto; */
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-left: 1px solid rgb(206, 206, 206);
}

.react-calendar__navigation button,
.react-calendar__year-view__months__month,
.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year {
  font-size: 24px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: 0.065em;
  text-align: center;
  color: #023047;
}

/* Hide default buttons provided by react-calendar */
.react-calendar__navigation button {
  font-size: 0.8em;
}

/* Style the month and year header */
.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 2vw;
  color: #000000;
  width: 26vw;
  margin: 0 auto;
  position: relative;
}

/* Style the days of the week header */
.react-calendar__month-view__weekdays {
  text-align: center;
  color: #333;
  /* font-weight: bold; */
  font-size: 16px;
  display: flex;
  /* gap: 10px; */
  /* width: 90%; */
  /* background-color: #e90303; */

}

/* .react-calendar__month-view__weekdays__weekday {
  overflow: visible !important;
  background-color: #31e23f;

} */

.react-calendar__month-view__weekdays abbr {
  width: 90px !important;
  text-decoration: none;
  font-size: 18px;
}

/*! Style each day tile */

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
  row-gap: 5px;
  column-gap: 2px !important;
  padding-top: 10px;
  justify-items: center;
}

.react-calendar__month-view__days__day {
  color: #333;
  border-radius: 10px;
  text-align: right;
  position: relative;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #e90303;
}

/* Style tiles that are not in the current month */
.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #989898;
  color: #989898;
}

.custom-div .react-calendar__tile {
  background-color: #ffffff;
  width: 98%;
  height: 14vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.custom-div .today-highlight {
  background-color: #023047 !important;
  color: white;
}

.react-calendar__tile abbr {
  font-size: 1vw;
  align-self: flex-end;
}

/* Style for the current day */
.custom-div .react-calendar__tile--active {
  background-color: #009688;
  color: white;
}


/* Style for the days with events */
.event {
  background-color: #f5f5f5;
  color: #333;
}

/* Badge for the number of visits */
.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 50px;
  /* adjust as needed */
  height: 20px;
  /* adjust as needed */
  background-color: #ffd700;
  border-radius: 10px;
  font-size: 0.8em;
  color: #a20000;
}

/* Different colors for badges based on some logic, e.g., number of visits */
.badge-low {
  background-color: #90ee90;
}

.badge-medium {
  background-color: #ffd700;
}

.badge-high {
  background-color: #ff4500;
}

.custom-button {
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -10px;
  right: 26px;
  background-color: #ffd56d;
  color: #000000;
  padding: 8px 50px;
  border-radius: 6px;
  margin: 32px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.custom-button img {
  margin-right: 12%;
}

.custom-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 8vw;
  height: 25%;
  border-radius: 5px;
  color: #fff;
  align-items: center;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
}

.custom-label-count {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.count-box {
  padding: 2px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #7e818254;
}

.custom-label-color {
  font-size: 1.7rem;
  width: 2.4vw;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: rgba(0, 0, 0, 0.6);
}