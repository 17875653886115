.edit-appointment_container::-webkit-scrollbar {
    display: none;
}

.edit-appointment_container-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-appointment_container {
    height: 85%;
    width: 800px !important;
    border-radius: 6px;
    background-color: white;
    padding: 30px;
    overflow-y: scroll;
    position: fixed;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.edit-appointment_header-close {
    display: flex;
    flex-direction: row-reverse;
    /* background-color: red; */
}

.edit-appointments-header {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-top: -25px;
}

.set-appointment-button-container {
    display: flex;
    gap: 10px;
}

.appointment-button_book,
.appointment-button_delete {
    border: none;
    width: 60%;
    color: white;
    padding: 10px 0px;
    border-radius: 4px;
    font-size: 18px;
    background-color: #219EBC;
}

.appointment-button_delete {
    width: 40%;
    background-color: #E2515E;
}